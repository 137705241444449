/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~animate.css/animate.min.css";

@import "~swiper/scss";
@import "~swiper/scss/autoplay";
@import "~swiper/scss/keyboard";
@import "~swiper/scss/pagination";
@import "~swiper/scss/scrollbar";
@import "~swiper/scss/zoom";
@import "~swiper/scss/navigation";
@import "~swiper/css/free-mode";
@import "~swiper/css/effect-fade";
@import "~swiper/css/effect-coverflow";

.reservationAppSwiperSlide.swiper-slide {
  width: auto !important;
}

.swiperSlideHomepageUpperSlider.swiper-slide {
  height: 215px;
  width: 382px;
  opacity: 1;
  margin-left: 30px;
  cursor: pointer;
}

.loyalty-swiper .swiper-pagination {
  // margin-bottom: 50px;
}

.fullScreenModal {
  --width: 100% !important;
  --height: 100% !important;
}

.extendModal {
  --border-radius: 10px;
  --backdrop-opacity: 0.1 !important;
  margin: auto !important;
  --max-height: 600px !important;
}

.extendModalCancelDate {
  margin: auto !important;
  --border-radius: 10px;
  --backdrop-opacity: 0.3 !important;
  --width: 500px;
  --height: 450px;
}

.scanningInstractions {
  --backdrop-opacity: 0.8 !important;
}

.fireBaseRecaptchaModal {
  --backdrop-opacity: 0.8 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 300px !important;

    &.bottom {
      align-items: flex-end;
    }

    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 300px;
        overflow: auto;
        padding: 10px;
      }
    }
  }
}

.showModalTop {
  position: absolute !important;
  top: 0px !important;
}

.copied-to-clipboard {
  --max-width: fit-content !important;
}

.affiliate-info-modal {
  --border-radius: 15px;

  .modal-wrapper {
    --width: auto !important;
    --max-width: 800px !important;

    &.bottom {
      align-items: flex-end;
    }

    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 300px;
        overflow: auto;
        padding: 10px;
      }
    }
  }
}

.migratePointsModal {
  --border-radius: 15px;

  .modal-wrapper {
    --width: auto !important;
    --max-width: 800px !important;

    &.bottom {
      align-items: flex-end;
    }

    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 300px;
        overflow: auto;
        padding: 10px;
      }
    }
  }
}

.cartPointsRewardsModal {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 420px !important;
    --max-height: 500px !important;
  }
}

.scanLoginModal {
  --backdrop-opacity: 0.95 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 300px !important;
    --max-height: 400px !important;
  }
}

.scanLoginModalSmall {
  --backdrop-opacity: 0.95 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 300px !important;
    --max-height: 400px !important;
  }
}

.extraAddressDetailsModal {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 300px !important;
    --max-height: 320px !important;
  }
}

.fontSizeSmallerP {
  font-size: small !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
}

.fontSizeSmaller {
  font-weight: normal !important;
  opacity: 0.7;
  font-size: small !important;
}

.line-through {
  text-decoration-line: line-through !important;
  -webkit-text-decoration-line: line-through !important;
}

.warningColor {
  color: var(--categoriesWarningColor) !important;
}

.categoriesWarningColor {
  color: var(--categoriesWarningColor) !important;
  border-color: var(--categoriesWarningColor) !important;
}

//Popups and Modals

.invoiceListModal {
  --backdrop-opacity: 0.75 !important;
}

@media screen and (min-width: 500px) {
  .invoiceListModal {
    --border-radius: 8px !important;
    --backdrop-opacity: 0.75 !important;

    &::part(content) {
      --max-width: 450px !important;
      --max-height: 550px !important;
    }
  }
}

.invoiceAddEditModalCss {
  --backdrop-opacity: 0.75 !important;
}

.productValidDeliveryAreasPopup {
  --border-radius: 8px !important;
  --backdrop-opacity: 0.85 !important;
}

@media screen and (min-width: 500px) {
  .invoiceAddEditModalCss {
    ion-toolbar {
      --ion-safe-area-top: 0 !important;
    }

    --backdrop-opacity: 0.75 !important;

    &::part(content) {
      --max-width: 350px !important;
      --max-height: 500px !important;
    }
  }
}

.rateAppModal {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 350px !important;
    --max-height: 360px !important;
  }
}

.preorderPopup {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 350px !important;
    --max-height: 400px !important;
  }
}

.reservation-select-payment-method-modal {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  --height: 100% !important;
  --width: 100% !important;
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-height: 600px !important;
    --max-width: 350px !important;
  }
}

.reservationDatePopup {
  --backdrop-opacity: 0.25 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 350px !important;
    --max-height: 266px !important;
  }
}

.deleted-user-info-modal {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 330px !important;
    --max-height: 400px !important;
  }
}

.create-order-failed-preorder-modal {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 330px !important;
    --max-height: 400px !important;
  }
}

.allergensModal {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 350px !important;
    --max-height: 350px !important;
  }
}

.submit-user-data-modal {
  --border-radius: 8px !important;
  --backdrop-opacity: 0.75 !important;
  &::part(content) {
    --max-height: 400px !important;
    --max-width: 340px !important;
  }
}

@media screen and (max-width: 450px) {
  .kioskGetPromoModal {
    --width: 100% !important;
    --height: 100% !important;
  }
}

@media screen and (min-width: 451px) {
  .kioskGetPromoModal {
    --border-radius: 8px !important;
    --backdrop-opacity: 0.75 !important;

    ion-toolbar {
      --ion-safe-area-top: 0 !important;
    }

    &::part(content) {
      --max-width: 430px !important;
      --max-height: 650px !important;
    }
  }
}

.kioskQrInfoModal {
  --border-radius: 8px !important;
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 400px !important;
    --max-height: 500px !important;
  }
}

.CouponsScheduleInfoPopup {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 350px !important;
    --max-height: 350px !important;
  }
}

.infoMessagesModalBrowser {
  --border-radius: 10px !important;
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 400px !important;
    --max-height: 600px !important;
  }
}

.infoMessagesModalMobile {
  --border-radius: 10px !important;
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 340px !important;
    --max-height: 550px !important;
  }
}

.infoMessagesModalBrowserNoImage {
  --border-radius: 10px !important;
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 380px !important;
    --max-height: 340px !important;
  }
}

.infoMessagesModalMobileNoImage {
  --border-radius: 10px !important;
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 340px !important;
    --max-height: 340px !important;
  }
}

.infoMessagesModal {
  --border-radius: 10px !important;
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 340px !important;
    --max-height: 550px !important;
  }
}

.dineInModalTransparent {
  ion-grid,
  ion-row,
  ion-col {
    background-color: transparent !important;
  }

  --backdrop-opacity: 0.9 !important;
  --background: transparent !important;
  --background-color: transparent !important;
  --box-shadow: 0 !important;

  --height: 100% !important;
  --width: 100% !important;

  &::part(content) {
  }
}

.dineInModal {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 320px !important;
    --max-height: 250px !important;
  }
}

.magicLoginPopupCss {
  &::part(content) {
    --max-height: var(--magicLoginPopupHeight) !important;
    --max-width: 350px !important;

    &.bottom {
      align-items: flex-end;
    }

    --height: auto;

    .ion-page {
      position: relative;
      display: block;
      contain: content;

      .inner-content {
        max-height: 300px;
        overflow: auto;
        padding: 10px;
      }
    }
  }
}

.orderNowPopupCss {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  --backdrop-opacity: 0.9 !important;
  --background: transparent !important;
  --background-color: transparent !important;
  --box-shadow: 0 !important;

  --height: 100% !important;
  --width: 100% !important;

  &::part(content) {
  }

  // &::part(content) {
  //

  //   &.bottom {
  //     align-items: flex-end;
  //   }

  //   --height: auto;

  //   .ion-page {
  //     position: relative;
  //     display: block;
  //     contain: content;

  //     .inner-content {
  //       max-height: 500px;
  //       overflow: auto;
  //       padding: 10px;
  //     }
  //   }
  // }
}

.sendOrderAndPayModalWithInfoMessage {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 320px !important;
    --max-height: 550px !important;
  }
}

.sendOrderAndPayModal {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 320px !important;
    --max-height: 420px !important;
  }
}

.sendOrderAndPayModalSmall {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 320px !important;
    --max-height: 250px !important;
  }
}

.sendOrderModal {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 300px !important;
    --max-height: 350px !important;
  }
}

.sendOrderModalWithInfoMessage {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 320px !important;
    --max-height: 500px !important;
  }
}

.openDeliveryAreaInfoModal {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 300px !important;
    --max-height: 300px !important;
  }
}

.orderNowSettingsPopupThreeStores {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --border-radius: 8px !important;
  --backdrop-opacity: 0.75 !important;
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --max-height: 350px !important;
  --max-width: 350px !important;
  &::part(content) {
    --height: 100% !important;
    --width: 100% !important;
  }
}

.orderNowSettingsPopupMultipleStores {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --max-height: 600px !important;
  --max-width: 400px !important;
  &::part(content) {
    --height: 100% !important;
    --width: 100% !important;
  }
}

.orderNowSettingsPopup {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --width: 330px !important;
    --max-height: 300px !important;
  }
}

.billingInfoVerifyModal {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-height: 400px !important;
    --width: 320px !important;
  }
}

.billingInfoPopupCss {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-height: 450px !important;
    --width: 320px !important;
  }
}

.changeQuantityPopover {
  --width: 150px !important;
}

.navigationToBankModal {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --border-radius: 9px !important;
    --max-height: 575px !important;
    --max-width: 330px !important;
  }
}

.paymentModal {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --height: var(--paymentModalHeight) !important;
    --width: var(--paymentModalWidth) !important;
  }
}

.selectStoreImagePopup {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  --backdrop-opacity: 0.9 !important;
  --background: transparent !important;
  --background-color: transparent !important;
  --box-shadow: 0 !important;

  ion-content,
  ion-header,
  ion-toolbar {
    --backdrop-opacity: 0.9 !important;
    --background: transparent !important;
    --background-color: transparent !important;
    --box-shadow: 0 !important;
  }

  --height: 100% !important;
  --width: 100% !important;
}

.user-winner-modal .ion-page {
  justify-content: center !important;
}

.user-winner-modal {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  --backdrop-opacity: 0.8 !important;
  --background: transparent !important;
  --background-color: transparent !important;
  --box-shadow: 0 !important;

  ion-content {
    --background: transparent !important;
  }
}

// @media screen and (min-width: 650px) {
//   .selectStoreImagePopup {
//     ion-toolbar {
//       --ion-safe-area-top: 0 !important;
//     }
//     --backdrop-opacity: 0.75 !important;
//     --border-radius: 10px !important;
//     .modal-wrapper {
//       --max-height: 450px !important;
//       --width: 650px !important;
//     }
//   }
// }

.selectStorePopupCssThreeStores {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --max-height: 350px !important;
  --max-width: 350px !important;
  &::part(content) {
    --height: 100% !important;
    --width: 100% !important;
  }
}

.selectStorePopupCssMultipleStores {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  --max-height: 600px !important;
  --max-width: 400px !important;
  &::part(content) {
    --height: 100% !important;
    --width: 100% !important;
  }
}

.selectStorePopupCss {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --border-radius: 8px !important;
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-height: var(--selectStoreInPaymentCardsPopupHeight) !important;
    --width: 330px !important;
  }
}

.selectStoreInPaymentCardsPopup {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  --border-radius: 8px !important;
  --backdrop-opacity: 0.75 !important;

  &::part(content) {
    --max-height: var(--selectStoreInPaymentCardsPopupHeight) !important;
    --width: 330px !important;
    --backdrop-opacity: 0.75 !important;
  }
}

.selectPaymentMethodPopup {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  --backdrop-opacity: 0.9 !important;
  --background: transparent !important;
  --background-color: transparent !important;
  --box-shadow: 0 !important;
  --border-radius: 8px !important;
  --height: 100% !important;
  --width: 100% !important;

  --max-width: 700px !important;
}

.selectPaymentGatewayForTokenizationPopup {
  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --border-radius: 8px !important;

  &::part(content) {
    --max-height: var(--selectStorePopupHeight) !important;
    --width: 310px !important;
    --backdrop-opacity: 0.75 !important;
  }
}

.magicLoginConfirmation {
  --backdrop-opacity: 0.9 !important;
  --background: rgb(66, 66, 66) !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --width: 310px !important;
    --max-height: 360px !important;
  }
}

.removePaddings {
  margin-inline-end: 2px;
  margin-inline-start: 2px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding: 0px;
  margin: 0px;
}

.catalog-search-modal {
  --backdrop-opacity: 0.75 !important;

  &::part(content) {
  }
}

@media screen and (min-width: 1000px) {
  .catalog-search-modal {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;

    &::part(content) {
      --height: 80%;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .catalog-search-modal {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;

    &::part(content) {
      --max-width: 600px;
      --width: 80%;
      --height: 100%;
    }
  }
}

.no-location-dine-in-modal {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-height: 450px !important;
    --max-width: 320px !important;
  }
}

.check-gps-coupon-rule-modal {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-height: 480px !important;
    --max-width: 320px !important;
  }
}

.fixed-address-info-modal {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;

  &::part(content) {
    --max-height: 550px !important;
    --max-width: 350px !important;
  }
}

.generalInfoModal {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  --max-height: 480px !important;
  --max-width: 330px !important;
}

.delivery-info-modal {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  --max-height: 480px !important;
  --max-width: 350px !important;
}

.delivery-cost-info-modal {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  --max-height: 500px !important;
  --max-width: 360px !important;
}

.coupon-info-popup {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-height: 380px !important;
    --max-width: 320px !important;
  }
}

.recommended-products-modal {
  --backdrop-opacity: 0.75 !important;
}

@media screen and (min-width: 767px) {
  .recommended-products-modal {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;
    --max-width: 800px;

    &::part(content) {
      --height: 90%;
      --width: 90%;
    }
  }
}

.unavailable-products-modal {
  --backdrop-opacity: 0.75 !important;

  &::part(content) {
  }
}

@media screen and (min-width: 1000px) {
  .unavailable-products-modal {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;

    &::part(content) {
      --height: 80%;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .unavailable-products-modal {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;

    &::part(content) {
      --max-width: 600px;
      --width: 80%;
      --height: 100%;
    }
  }
}

.productDetailsModal {
  --backdrop-opacity: 0.75 !important;

  &::part(content) {
  }

  ion-footer {
    margin-bottom: calc((var(--ion-safe-area-top)) / 2) !important;
  }
}

@media screen and (max-width: 340px) {
  .quantityButtonsProductDetailsModal {
    --size: small !important;
  }
}

@media screen and (min-width: 1200px) {
  .productDetailsModalBig {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;
    --min-width: 1150px;
    --max-width: 1200px;

    &::part(content) {
      --height: 80%;
    }
  }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .productDetailsModalBig {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;

    &::part(content) {
      --max-width: 1000px;

      --height: 80%;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .productDetailsModalBig {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;

    &::part(content) {
      --max-width: 600px;
      --width: 80%;
      --height: 100%;
    }
  }
}

.productDetailsModalSquareImageDesignNoOptions {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  --max-height: 650px;
  --max-width: 480px;

  &::part(content) {
    --height: 100%;
    --width: 100%;
  }
}

.productDetailsModalBigSquareImageDesign {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  --max-height: 800px;
  --max-width: 950px;

  &::part(content) {
    --height: 100%;
    --width: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .productDetailsModal {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;

    &::part(content) {
      --max-width: 600px;

      --height: 80%;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .productDetailsModal {
    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 800px;

    &::part(content) {
      --max-width: 600px;
      --width: 80%;
      --height: 100%;
    }
  }
}

@media screen and (min-width: 450px) {
  .customerPrefsModal {
    --backdrop-opacity: 0.65 !important;

    ion-toolbar {
      --ion-safe-area-top: 0 !important;
    }

    &::part(content) {
      --max-height: 450px;
      --max-width: 450px;
    }
  }
}

.previousOrderModal {
  --backdrop-opacity: 0.65 !important;
  --max-height: 500px;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --height: 70% !important;
    --max-width: 500px;
  }
}

.productDetailsModalSmall {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  --backdrop-opacity: 0.75 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --height: 60%;
  }
}

@media screen and (min-width: 1000px) {
  .productDetailsModalSmall {
    ion-toolbar {
      --ion-safe-area-top: 0 !important;
    }

    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 400px;

    &::part(content) {
      --max-width: 600px;
      --height: 80%;
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .productDetailsModalSmall {
    ion-toolbar {
      --ion-safe-area-top: 0 !important;
    }

    --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-height: 400px;

    &::part(content) {
      --max-width: 600px;
      --width: 80%;
      --height: 100%;
    }
  }
}

.display-none-product-details {
  --height: 0;
  --width: 0;
  --backdrop-opacity: 0 !important;
  z-index: -20 !important;
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  .radioClosedChoicesSelectionModal {
    --backdrop-opacity: 0.75 !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .radioClosedChoicesSelectionModal {
    --backdrop-opacity: 0.75 !important;
    --height: auto;

    .bottom {
      align-items: flex-end;
    }

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }

    .inner-content {
      max-height: 60vh;
      overflow: auto;
      padding: 10px;
    }

    // &::part(content) {
    //   --max-width: 500px !important;

    // }
  }
}

@media screen and (min-width: 1000px) {
  .radioClosedChoicesSelectionModal {
    --backdrop-opacity: 0.75 !important;
    --height: auto;

    .bottom {
      align-items: flex-end;
    }

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }

    .inner-content {
      max-height: 60vh;
      overflow: auto;
      padding: 10px;
    }

    // &::part(content) {
    //   --max-width: 500px !important;

    // }
  }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  .checkboxClosedChoicesModal {
    --backdrop-opacity: 0.75 !important;

    &::part(content) {
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .checkboxClosedChoicesModal {
    --backdrop-opacity: 0.75 !important;
    --height: auto;

    .bottom {
      align-items: flex-end;
    }

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }

    .inner-content {
      max-height: 60vh;
      overflow: auto;
      padding: 10px;
    }

    // &::part(content) {
    //   --max-width: 500px !important;

    // }
  }
}

@media screen and (min-width: 1000px) {
  .checkboxClosedChoicesModal {
    --backdrop-opacity: 0.75 !important;
    --height: auto;

    .bottom {
      align-items: flex-end;
    }

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }

    .inner-content {
      max-height: 60vh;
      overflow: auto;
      padding: 10px;
    }

    // &::part(content) {
    //   --max-width: 500px !important;

    // }
  }
}

.fullscreen-image-popup {
  --background: transparent !important;
  --backdrop-opacity: 0.8 !important;

  &::part(content) {
    border: none !important;
    --width: 95% !important;
    --min-width: 200px !important;
    --min-width: 200px !important;
    --max-width: 450px !important;
    --height: 95% !important;
    --max-height: 450px !important;
  }
}

.invert {
  filter: invert(1) !important;
}

.ion-color-customSideMenuBackground {
  --ion-color-base: var(--customSideMenuBackground) !important;
  --ion-color-contrast: var(--customSideMenuBackground-contrast) !important;
}

.ion-color-customSideMenuItemBackground {
  --ion-color-base: var(--ion-color-customSideMenuItemBackground) !important;
  --ion-color-base-rgb: var(
    --ion-color-customSideMenuItemBackground-rgb
  ) !important;
  --ion-color-contrast: var(
    --ion-color-customSideMenuItemBackground-contrast
  ) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-customSideMenuItemBackground-contrast-rgb
  ) !important;
  --ion-color-shade: var(
    --ion-color-customSideMenuItemBackground-shade
  ) !important;
  --ion-color-tint: var(
    --ion-color-customSideMenuItemBackground-tint
  ) !important;
}

.ion-color-horizontalCategories {
  --ion-color-base: var(--ion-color-horizontalCategories) !important;
  --ion-color-base-rgb: var(--ion-color-horizontalCategories-rgb) !important;
  --ion-color-contrast: var(
    --ion-color-horizontalCategories-contrast
  ) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-horizontalCategories-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-horizontalCategories-shade) !important;
  --ion-color-tint: var(--ion-color-horizontalCategories-tint) !important;
}

.ion-color-verticalCategories {
  --ion-color-base: var(--ion-color-verticalCategories) !important;
  --ion-color-base-rgb: var(--ion-color-verticalCategories-rgb) !important;
  --ion-color-contrast: var(--ion-color-verticalCategories-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-verticalCategories-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-verticalCategories-shade) !important;
  --ion-color-tint: var(--ion-color-verticalCategories-tint) !important;
}

//orders page
.ion-color-processing {
  --ion-color-base: var(--ion-color-processing) !important;
}

.ion-color-complete {
  --ion-color-base: var(--ion-color-complete) !important;
  --ion-color-base-rgb: var(--ion-color-complete-rgb) !important;
  --ion-color-contrast: var(--ion-color-complete-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-complete-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-complete-shade) !important;
  --ion-color-tint: var(--ion-color-complete-tint) !important;
}

.ion-color-processingPayment {
  --ion-color-base: var(--ion-color-processingPayment) !important;
  --ion-color-base-rgb: var(--ion-color-processingPayment-rgb) !important;
  --ion-color-contrast: var(--ion-color-processingPayment-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-processingPayment-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-processingPayment-shade) !important;
  --ion-color-tint: var(--ion-color-processingPayment-tint) !important;
}

.ion-color-pendingPayment {
  --ion-color-base: var(--ion-color-pendingPayment) !important;
  --ion-color-base-rgb: var(--ion-color-pendingPayment-rgb) !important;
  --ion-color-contrast: var(--ion-color-pendingPayment-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-pendingPayment-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-pendingPayment-shade) !important;
  --ion-color-tint: var(--ion-color-pendingPayment-tint) !important;
}

.ion-color-pending {
  --ion-color-base: var(--ion-color-pending) !important;
  --ion-color-base-rgb: var(--ion-color-pending-rgb) !important;
  --ion-color-contrast: var(--ion-color-pending-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-pending-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-pending-shade) !important;
  --ion-color-tint: var(--ion-color-pending-tint) !important;
}

.ion-color-canceled {
  --ion-color-base: var(--ion-color-canceled) !important;
  --ion-color-base-rgb: var(--ion-color-canceled-rgb) !important;
  --ion-color-contrast: var(--ion-color-canceled-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-canceled-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-canceled-shade) !important;
  --ion-color-tint: var(--ion-color-canceled-tint) !important;
}

.ion-color-homepageBackground {
  --ion-color-base: var(--ion-color-homepageBackground) !important;
  --ion-color-base-rgb: var(--ion-color-homepageBackground-rgb) !important;
  --ion-color-contrast: var(--ion-color-homepageBackground-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-homepageBackground-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-homepageBackground-shade) !important;
  --ion-color-tint: var(--ion-color-homepageBackground-tint) !important;
}

//accountSettingsPage

.ion-color-accountSettingsSaveBtn {
  --ion-color-base: var(--ion-color-accountSettingsSaveBtn) !important;
  --ion-color-base-rgb: var(--ion-color-accountSettingsSaveBtn-rgb) !important;
  --ion-color-contrast: var(
    --ion-color-accountSettingsSaveBtn-contrast
  ) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-accountSettingsSaveBtn-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-accountSettingsSaveBtn-shade) !important;
  --ion-color-tint: var(--ion-color-accountSettingsSaveBtn-tint) !important;
}

.ion-color-accountSettingsEditBtn {
  --ion-color-base: var(--ion-color-accountSettingsEditBtn) !important;
  --ion-color-base-rgb: var(--ion-color-accountSettingsEditBtn-rgb) !important;
  --ion-color-contrast: var(
    --ion-color-accountSettingsEditBtn-contrast
  ) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-accountSettingsEditBtn-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-accountSettingsEditBtn-shade) !important;
  --ion-color-tint: var(--ion-color-accountSettingsEditBtn-tint) !important;
}

//Discount Coupons Page

.ion-color-used-coupon {
  --ion-color-base: var(--ion-color-used-coupon) !important;
  --ion-color-base-rgb: var(--ion-color-used-coupon-rgb) !important;
  --ion-color-contrast: var(--ion-color-used-coupon-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-used-coupon-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-used-coupon-shade) !important;
  --ion-color-tint: var(--ion-color-used-coupon-tint) !important;
}

.ion-color-expired-coupon {
  --ion-color-base: var(--ion-color-expired-coupon) !important;
  --ion-color-base-rgb: var(--ion-color-expired-coupon-rgb) !important;
  --ion-color-contrast: var(--ion-color-expired-coupon-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-expired-coupon-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-expired-coupon-shade) !important;
  --ion-color-tint: var(--ion-color-expired-coupon-tint) !important;
}

.ion-color-active-coupon {
  --ion-color-base: var(--ion-color-active-coupon) !important;
  --ion-color-base-rgb: var(--ion-color-active-coupon-rgb) !important;
  --ion-color-contrast: var(--ion-color-active-coupon-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-active-coupon-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-active-coupon-shade) !important;
  --ion-color-tint: var(--ion-color-active-coupon-tint) !important;
}

.ion-color-notValid {
  --ion-color-base: var(--ion-color-notValid) !important;
  --ion-color-base-rgb: var(--ion-color-notValid-rgb) !important;
  --ion-color-contrast: var(--ion-color-notValid-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-notValid-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-notValid-shade) !important;
  --ion-color-tint: var(--ion-color-notValid-tint) !important;
}

.ion-color-completeorder {
  --ion-color-base: var(--ion-color-completeorder) !important;
  --ion-color-base-rgb: var(--ion-color-completeorder-rgb) !important;
  --ion-color-contrast: var(--ion-color-completeorder-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-completeorder-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-completeorder-shade) !important;
  --ion-color-tint: var(--ion-color-completeorder-tint) !important;
}

.ion-color-ordererror {
  --ion-color-base: var(--ion-color-ordererror) !important;
  --ion-color-base-rgb: var(--ion-color-ordererror-rgb) !important;
  --ion-color-contrast: var(--ion-color-ordererror-contrast) !important;
  --ion-color-contrast-rgb: var(--ion-color-ordererror-contrast-rgb) !important;
  --ion-color-shade: var(--ion-color-ordererror-shade) !important;
  --ion-color-tint: var(--ion-color-ordererror-tint) !important;
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-grey-tint: var(--ion-color-grey-tint);
}

.ion-color-selectPaymentMethod {
  --ion-color-base: var(--ion-color-selectPaymentMethod);
  --ion-color-base-rgb: var(--ion-color-selectPaymentMethod-rgb);
  --ion-color-contrast: var(--ion-color-selectPaymentMethod-contrast);
  --ion-color-contrast-rgb: var(--ion-color-selectPaymentMethod-contrast-rgb);
  --ion-color-shade: var(--ion-color-selectPaymentMethod-shade);
  --ion-color-grey-tint: var(--ion-color-selectPaymentMethod-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-grey-tint: var(--ion-color-white-tint);
}

.ion-color-homePageMotoColor {
  --ion-color-base: var(--ion-color-homePageMotoColor);
  --ion-color-base-rgb: var(--ion-color-homePageMotoColor-rgb);
  --ion-color-contrast: var(--ion-color-homePageMotoColor-contrast);
  --ion-color-contrast-rgb: var(--ion-color-homePageMotoColor-contrast-rgb);
  --ion-color-shade: var(--ion-color-homePageMotoColor-shade);
  --ion-color-grey-tint: var(--ion-color-homePageMotoColor-tint);
}

.ion-color-black {
  --ion-color-base: var(--ion-color-black);
  --ion-color-base-rgb: var(--ion-color-black-rgb);
  --ion-color-contrast: var(--ion-color-black-contrast);
  --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
  --ion-color-shade: var(--ion-color-black-shade);
  --ion-color-grey-tint: var(--ion-color-black-tint);
}

.ion-color-selected {
  --ion-color-base: var(--ion-color-selected);
  --ion-color-base-rgb: var(--ion-color-selected-rgb);
  --ion-color-contrast: var(--ion-color-selected-contrast);
  --ion-color-contrast-rgb: var(--ion-color-selected-contrast-rgb);
  --ion-color-shade: var(--ion-color-selected-shade);
  --ion-color-grey-tint: var(--ion-color-selected-tint);
}

.ion-color-deliveryMethodAddressButtons {
  --ion-color-base: var(--ion-color-deliveryMethodAddressButtons);
  --ion-color-base-rgb: var(--ion-color-deliveryMethodAddressButtons-rgb);
  --ion-color-contrast: var(--ion-color-deliveryMethodAddressButtons-contrast);
  --ion-color-contrast-rgb: var(
    --ion-color-deliveryMethodAddressButtons-contrast-rgb
  );
  --ion-color-shade: var(--ion-color-deliveryMethodAddressButtons-shade);
  --ion-color-grey-tint: var(--ion-color-deliveryMethodAddressButtons-tint);
}

.ion-color-homepageButtons {
  --ion-color-base: var(--ion-color-homepageButtons) !important;
  --ion-color-base-rgb: var(--ion-color-homepageButtons-rgb) !important;
  --ion-color-contrast: var(--ion-color-homepageButtons-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-homepageButtons-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-homepageButtons-shade) !important;
  --ion-color-tint: var(--ion-color-homepageButtons-tint) !important;
}

.ion-color-tagColorOne {
  --ion-color-base: var(--ion-color-tagColorOne) !important;
  --ion-color-base-rgb: var(--ion-color-tagColorOne-rgb) !important;
  --ion-color-contrast: var(--ion-color-tagColorOne-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-tagColorOne-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-tagColorOne-shade) !important;
  --ion-color-tint: var(--ion-color-tagColorOne-tint) !important;
}

.ion-color-tagColorTwo {
  --ion-color-base: var(--ion-color-tagColorTwo) !important;
  --ion-color-base-rgb: var(--ion-color-tagColorTwo-rgb) !important;
  --ion-color-contrast: var(--ion-color-tagColorTwo-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-tagColorTwo-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-tagColorTwo-shade) !important;
  --ion-color-tint: var(--ion-color-tagColorTwo-tint) !important;
}

.ion-color-tagColorThree {
  --ion-color-base: var(--ion-color-tagColorThree) !important;
  --ion-color-base-rgb: var(--ion-color-tagColorThree-rgb) !important;
  --ion-color-contrast: var(--ion-color-tagColorThree-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-tagColorThree-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-tagColorThree-shade) !important;
  --ion-color-tint: var(--ion-color-tagColorThree-tint) !important;
}

.ion-color-tagColorFour {
  --ion-color-base: var(--ion-color-tagColorFour) !important;
  --ion-color-base-rgb: var(--ion-color-tagColorFour-rgb) !important;
  --ion-color-contrast: var(--ion-color-tagColorFour-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-tagColorFour-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-tagColorFour-shade) !important;
  --ion-color-tint: var(--ion-color-tagColorFour-tint) !important;
}

.ion-color-tagColorFive {
  --ion-color-base: var(--ion-color-tagColorFive) !important;
  --ion-color-base-rgb: var(--ion-color-tagColorFive-rgb) !important;
  --ion-color-contrast: var(--ion-color-tagColorFive-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-tagColorFive-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-tagColorFive-shade) !important;
  --ion-color-tint: var(--ion-color-tagColorFive-tint) !important;
}

.ion-color-tagColorSix {
  --ion-color-base: var(--ion-color-tagColorSix) !important;
  --ion-color-base-rgb: var(--ion-color-tagColorSix-rgb) !important;
  --ion-color-contrast: var(--ion-color-tagColorSix-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-tagColorSix-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-tagColorSix-shade) !important;
  --ion-color-tint: var(--ion-color-tagColorSix-tint) !important;
}

.ion-color-tagColorSeven {
  --ion-color-base: var(--ion-color-tagColorSeven) !important;
  --ion-color-base-rgb: var(--ion-color-tagColorSeven-rgb) !important;
  --ion-color-contrast: var(--ion-color-tagColorSeven-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-tagColorSeven-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-tagColorSeven-shade) !important;
  --ion-color-tint: var(--ion-color-tagColorSeven-tint) !important;
}

.ion-color-homeBurgerButton {
  --ion-color-base: var(--ion-color-homeBurgerButton) !important;
  --ion-color-base-rgb: var(--ion-color-homeBurgerButton-rgb) !important;
  --ion-color-contrast: var(--ion-color-homeBurgerButton-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-homeBurgerButton-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-homeBurgerButton-shade) !important;
  --ion-color-tint: var(--ion-color-homeBurgerButton-tint) !important;
}

.ion-color-homeLoginButton {
  --ion-color-base: var(--ion-color-homeLoginButton) !important;
  --ion-color-base-rgb: var(--ion-color-homeLoginButton-rgb) !important;
  --ion-color-contrast: var(--ion-color-homeLoginButton-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-homeLoginButton-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-homeLoginButton-shade) !important;
  --ion-color-tint: var(--ion-color-homeLoginButton-tint) !important;
}

.ion-color-ionMenuSelectedColor {
  --ion-color-base: var(--ion-color-ionMenuSelectedColor) !important;
  --ion-color-base-rgb: var(--ion-color-ionMenuSelectedColor-rgb) !important;
  --ion-color-contrast: var(
    --ion-color-ionMenuSelectedColor-contrast
  ) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-ionMenuSelectedColor-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-ionMenuSelectedColor-shade) !important;
  --ion-color-tint: var(--ion-color-ionMenuSelectedColor-tint) !important;
}

.ion-color-changeDarkModeButton {
  --ion-color-base: var(--ion-color-changeDarkModeButton) !important;
  --ion-color-base-rgb: var(--ion-color-changeDarkModeButton-rgb) !important;
  --ion-color-contrast: var(
    --ion-color-changeDarkModeButton-contrast
  ) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-changeDarkModeButton-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-changeDarkModeButton-shade) !important;
  --ion-color-tint: var(--ion-color-changeDarkModeButton-tint) !important;
}

.ion-color-poweredByTextColor {
  --ion-color-base: var(--ion-color-poweredByTextColor) !important;
  --ion-color-base-rgb: var(--ion-color-poweredByTextColor-rgb) !important;
  --ion-color-contrast: var(--ion-color-poweredByTextColor-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-poweredByTextColor-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-poweredByTextColor-shade) !important;
  --ion-color-tint: var(--ion-color-poweredByTextColor-tint) !important;
}

.ion-color-kioskMemberCardColor {
  --ion-color-base: var(--ion-color-kioskMemberCardColor) !important;
  --ion-color-base-rgb: var(--ion-color-kioskMemberCardColor-rgb) !important;
  --ion-color-contrast: var(
    --ion-color-kioskMemberCardColor-contrast
  ) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-kioskMemberCardColor-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-kioskMemberCardColor-shade) !important;
  --ion-color-tint: var(--ion-color-kioskMemberCardColor-tint) !important;
}

.ion-color-ionMenuBadgesColor {
  --ion-color-base: var(--ion-color-ionMenuBadgesColor) !important;
  --ion-color-base-rgb: var(--ion-color-ionMenuBadgesColor-rgb) !important;
  --ion-color-contrast: var(--ion-color-ionMenuBadgesColor-contrast) !important;
  --ion-color-contrast-rgb: var(
    --ion-color-ionMenuBadgesColor-contrast-rgb
  ) !important;
  --ion-color-shade: var(--ion-color-ionMenuBadgesColor-shade) !important;
  --ion-color-tint: var(--ion-color-ionMenuBadgesColor-tint) !important;
}

.ionicLoadingCss {
  --backdrop-opacity: 0.75;
}

// .home-logo img {
//   max-width: 350px;
// }

// @media screen and (max-height: 500px) {
//   .home-logo img {
//     max-height: 180px;
//     max-width: 180px;
//   }
// }

@media screen and (min-height: 0px) and (max-height: 620px) {
  .home-logo-three-buttons-points img {
    max-width: 0px !important;
  }
}

@media screen and (min-height: 620px) and (max-height: 690px) {
  .home-logo-three-buttons-points img {
    max-width: 180px !important;
  }
}

@media screen and (min-height: 691px) and (max-height: 789px) {
  .home-logo-three-buttons-points img {
    max-width: 260px !important;
  }
}

@media screen and (min-height: 790px) {
  .home-logo-three-buttons-points img {
    max-width: 350px !important;
  }
}

@media screen and (min-height: 0px) and (max-height: 640px) {
  .home-logo-four-buttons-points img {
    max-width: 0px !important;
  }
}

@media screen and (min-height: 641px) and (max-height: 709px) {
  .home-logo-four-buttons-points img {
    max-width: 180px !important;
  }
}

@media screen and (min-height: 710px) and (max-height: 854px) {
  .home-logo-four-buttons-points img {
    max-width: 230px !important;
  }
}

@media screen and (min-height: 855px) {
  .home-logo-four-buttons-points img {
    max-width: 350px !important;
  }
}

@media screen and (min-height: 0px) and (max-height: 590px) {
  .home-logo-three-buttons img {
    max-width: 0px !important;
  }
}

@media screen and (min-height: 591px) and (max-height: 750px) {
  .home-logo-three-buttons img {
    max-width: 230px !important;
  }
}

@media screen and (min-height: 751px) {
  .home-logo-three-buttons img {
    max-width: 350px !important;
  }
}

@media screen and (min-height: 0px) and (max-height: 620px) {
  .home-logo-four-buttons img {
    max-width: 0px !important;
  }
}

@media screen and (min-height: 620px) and (max-height: 750px) {
  .home-logo-four-buttons img {
    max-width: 220px !important;
  }
}

@media screen and (min-height: 751px) {
  .home-logo-four-buttons img {
    max-width: 350px !important;
  }
}

@media screen and (min-height: 0px) and (max-height: 550px) {
  .home-logo-upper-slider img {
    max-width: 150px !important;
  }
}

@media screen and (min-height: 550px) {
  .home-logo-upper-slider img {
    max-width: 200px !important;
  }
}

// @media screen and (min-height: 751px) {
//   .home-logo-upper-slider img {
//     max-width: 350px;
//   }
// }

@media screen and (min-height: 0px) and (max-height: 550px) {
  .home-logo-image-select-store-system img {
    max-width: 180px;
  }
}

@media screen and (min-height: 550px) and (max-height: 830px) {
  .home-logo-image-select-store-system img {
    max-width: 190px;
  }
}

@media screen and (min-height: 830px) and (max-height: 870px) {
  .home-logo-image-select-store-system img {
    max-width: 240px;
  }
}

@media screen and (min-height: 870px) and (max-height: 970px) {
  .home-logo-image-select-store-system img {
    max-width: 275px;
  }
}

@media screen and (min-height: 970px) {
  .home-logo-image-select-store-system img {
    max-width: 350px;
  }
}

@media screen and (min-height: 0px) and (max-height: 550px) {
  .home-logo img {
    max-width: 180px;
  }
}

@media screen and (min-height: 550px) and (max-height: 750px) {
  .home-logo img {
    max-width: 240px;
  }
}

@media screen and (min-height: 751px) {
  .home-logo img {
    max-width: 350px;
  }
}

// @media screen and (min-width: 331px) and (max-width: 400px) {
//   .home-logo img {
//     max-width: 220px;
//   }
// }

@media screen and (min-height: 0px) and (max-height: 600px) {
  .home-logo-points img {
    max-width: 180px;
  }
}

@media screen and (min-height: 601px) and (max-height: 750px) {
  .home-logo-points img {
    max-width: 240px;
  }
}

@media screen and (min-height: 751px) {
  .home-logo-points img {
    max-width: 350px;
  }
}

// @media screen and (max-height: 500px) {
//   .home-logo img {
//     max-height: 180px;
//     max-width: 180px;
//   }
// }

// @media screen and (min-width: 0px) and (max-width: 330px) {
//   .home-logo-points {
//     margin-top: 50px;
//   }
// }

// @media screen and (min-width: 331px) and (max-width: 400px) {
//   .home-logo-points img {
//     max-width: 180px;
//   }
// }

@media screen and (max-width: 550px) {
  .hypercategories-selection-modal {
    --backdrop-opacity: 0.8 !important;

    &::part(content) {
      --width: 100% !important;
      --height: 100% !important;
    }
  }
}

@media screen and (min-width: 551px) {
  .hypercategories-selection-modal {
    --backdrop-opacity: 0.8 !important;
    --border-radius: 8px !important;

    &::part(content) {
      --max-width: 500px;
      --max-height: 1000px !important;
    }
  }
}

.block-store-modal {
  z-index: 1000 !important;
  --backdrop-opacity: 0.8 !important;

  &::part(content) {
    background: transparent !important;

    width: 100% !important;
    height: 100% !important;
  }
}

.loading-splashscreen-modal {
  z-index: 2147483645 !important;
  --width: 100% !important;
  --height: 100% !important;

  &::part(content) {
    --width: 100% !important;
    --height: 100% !important;
  }
}

.update-modal-class {
  z-index: 2147483647 !important;
  --backdrop-opacity: 1 !important;
  --background: #ffffff !important;
  --background-color: #ffffff !important;
  --box-shadow: 0 !important;
  --width: 100% !important;
  --height: 100% !important;
  --background-color: white !important;
}

@media screen and (min-width: 1000px) {
  .createCouponModal {
    // --backdrop-opacity: 0.75 !important;
    --border-radius: 8px !important;
    --max-width: 800px !important;

    &::part(content) {
      // --height: 80%;
      --width: 70% !important;
    }
  }
}

.checkStoreOnlineAlert {
  z-index: 999 !important;
}

.product-image-border {
  border: 1.5px solid var(--ion-color-primary) !important;
}

.product-image-border-radius {
  border: 1.5px solid var(--ion-color-primary) !important;
  border-radius: 10px !important;
}

.errorOnOrderCompletedAlert {
  --background: var(--ion-color-ordererror) !important;

  .alert-button.sc-ion-alert-md,
  .alert-button.sc-ion-alert-ios,
  .alert-message.sc-ion-alert-ios,
  .alert-message.sc-ion-alert-md,
  .alert-sub-title.sc-ion-alert-md,
  .alert-title.sc-ion-alert-md,
  .alert-sub-title.sc-ion-alert-ios,
  .alert-title.sc-ion-alert-ios {
    color: black;
  }

  z-index: 2147483647 !important;
}

.orderCompletedAlert {
  --background: var(--ion-color-completeorder) !important;

  .alert-button.sc-ion-alert-md,
  .alert-button.sc-ion-alert-ios,
  .alert-message.sc-ion-alert-ios,
  .alert-message.sc-ion-alert-md,
  .alert-sub-title.sc-ion-alert-md,
  .alert-title.sc-ion-alert-md,
  .alert-sub-title.sc-ion-alert-ios,
  .alert-title.sc-ion-alert-ios {
    color: black;
  }
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal !important;
}

.scrollHeight {
  height: var(--catalogScrollHeight) !important;
}

*.alert-checkbox-group::-webkit-scrollbar {
  width: 0.4em;
  display: block !important;
}

*.alert-checkbox-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*.alert-checkbox-group::-webkit-scrollbar-thumb {
  background-color: var(--ion-color-primary);
  outline: 1px solid var(--ion-color-primary);
  border-radius: 20px;
}

*.alert-radio-group::-webkit-scrollbar {
  width: 0.4em;
  display: block !important;
}

*.alert-radio-group::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*.alert-radio-group::-webkit-scrollbar-thumb {
  background-color: var(--ion-color-primary);
  outline: 1px solid var(--ion-color-primary);
  border-radius: 20px;
}

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.info-message-alert {
  .alert-message {
    color: var(--ion-color-dark) !important;
  }
}

@media screen and (min-width: 500px) {
  .contestModal {
    --backdrop-opacity: 0.25 !important;
    --border-radius: 8px !important;
    --max-width: 600px;
    --max-height: 600px;
  }
}

@media screen and (min-width: 500px) {
  .ReservationTableInfoModal {
    --backdrop-opacity: 0.25 !important;
    --width: 400px;
  }
}

.sendReservationAndPayModal {
  --backdrop-opacity: 0.25 !important;

  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  &::part(content) {
    --max-width: 320px !important;
    --max-height: 420px !important;
  }
}

@media screen and (min-width: 500px) {
  .fillRequiredNameModal {
    --backdrop-opacity: 0.25 !important;

    .modal-wrapper {
      --max-width: 350px !important;
      --max-height: 305px !important;
    }
  }
}

.seatsInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }
}

/* Firefox */
.seatsInput {
  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}

.invisibleDateTimePicker {
  --width: 450px;
  --height: 400px;

  --background: transparent !important;
  --box-shadow: 0;

  ion-content {
    ion-grid {
      height: 100% !important;
    }

    --background: transparent !important;

    ion-datetime {
      border-radius: 8px !important;
      border: 1px solid black !important;
    }
  }
}

.artistModalCss {
  --height: 100% !important;
  --width: 100% !important;
}

/**
  * Barcode Scanning
  * -------------------------------------------
  */
body.barcode-scanning-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

.barcode-scanning-modal {
  visibility: visible;
}

@media (prefers-color-scheme: dark) {
  .barcode-scanning-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}

.transparentCatalogElements {
  --ion-background-color: transparent !important;
  --ion-text-color: var(--catalog-text-color);
  --ion-color-verticalCategories: var(--catalog-text-color);

  ion-label,
  ion-content,
  ion-list,
  ion-card,
  div,
  span {
    --background: transparent !important;
    background-color: transparent !important;
    background: transparent !important;
    --ion-color-base: transparent !important;
  }

  ion-item,
  ion-row,
  ion-col,
  ion-grid {
    --background: transparent !important;
  }
}

.nonTransparentCatalogElements {
}

.whitePaginationSwiper {
  .swiper-pagination-bullet-active {
    background-color: white !important;
  }
  .swiper-pagination-bullet {
    background-color: white !important;
  }

  .swiper-pagination {
    top: 25px !important;
  }
}

.side-menu-full-screen {
  --min-width: 100% !important;
}
.fixedPointsAddressPrompt {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }

  --backdrop-opacity: 0.9 !important;
  --background: transparent !important;
  --background-color: transparent !important;
  --box-shadow: 0 !important;

  --height: 100% !important;
  --width: 100% !important;

  &::part(content) {
  }
}

.fixed-points-address-selection-modal {
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  ion-toolbar {
    --ion-safe-area-top: 0 !important;
  }
  --max-height: 350px !important;
  --max-width: 350px !important;
  &::part(content) {
    --height: 100% !important;
    --width: 100% !important;
  }
}

.full-screen-modal {
  ion-modal {
    height: 100% !important;
  }

  ion-content {
    height: 100% !important;
  }
}

.active-coupons-promo-modal {
  --backdrop-opacity: 0.75 !important;
  --border-radius: 8px !important;
  --max-width: 450px !important;
}

.reservationPointsProfileModal {
  --height: 100%;
  --width: 100%;
  --max-width: 350px !important;
  --max-height: 550px !important;
}
