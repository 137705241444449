// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-grey: #e6e7eb;
  --ion-color-grey-rgb: 230, 231, 235;
  --ion-color-grey-contrast: #000000;
  --ion-color-grey-contrast-rgb: 0, 0, 0;
  --ion-color-grey-shade: #cacbcf;
  --ion-color-grey-tint: #e9e9ed;

  --ion-color-selectPaymentMethod: #fafafa;
  --ion-color-selectPaymentMethod-rgb: 250, 250, 250;
  --ion-color-selectPaymentMethod-contrast: #000000;
  --ion-color-selectPaymentMethod-contrast-rgb: 0, 0, 0;
  --ion-color-selectPaymentMethod-shade: #dcdcdc;
  --ion-color-selectPaymentMethod-tint: #fbfbfb;

  --ion-color-completeorder: #66c27a;
  --ion-color-completeorder-rgb: 102, 194, 122;
  --ion-color-completeorder-contrast: #000000;
  --ion-color-completeorder-contrast-rgb: 0, 0, 0;
  --ion-color-completeorder-shade: #5aab6b;
  --ion-color-completeorder-tint: #75c887;

  --ion-color-ordererror: #ec6565;
  --ion-color-ordererror-rgb: 236, 101, 101;
  --ion-color-ordererror-contrast: #000000;
  --ion-color-ordererror-contrast-rgb: 0, 0, 0;
  --ion-color-ordererror-shade: #d05959;
  --ion-color-ordererror-tint: #ee7474;

  --ion-color-selected: #eb445a;
  --ion-color-selected-rgb: 235, 68, 90;
  --ion-color-selected-contrast: #ffffff;
  --ion-color-selected-contrast-rgb: 255, 255, 255;
  --ion-color-selected-shade: #cf3c4f;
  --ion-color-selected-tint: #ed576b;

  --ion-color-homepageButtons: var(--ion-color-primary);
  --ion-color-homepageButtons-rgb: var(--ion-color-primary-rgb);
  --ion-color-homepageButtons-contrast: var(--ion-color-primary-contrast);
  --ion-color-homepageButtons-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homepageButtons-shade: var(--ion-color-primary-shade);
  --ion-color-homepageButtons-tint: var(--ion-color-primary-tint);

  --ion-color-homeBurgerButton: var(--ion-color-primary);
  --ion-color-homeBurgerButton-rgb: var(--ion-color-primary-rgb);
  --ion-color-homeBurgerButton-contrast: var(--ion-color-primary-contrast);
  --ion-color-homeBurgerButton-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homeBurgerButton-shade: var(--ion-color-primary-shade);
  --ion-color-homeBurgerButton-tint: var(--ion-color-primary-tint);

  --ion-color-homeLoginButton: var(--ion-color-primary);
  --ion-color-homeLoginButton-rgb: var(--ion-color-primary-rgb);
  --ion-color-homeLoginButton-contrast: var(--ion-color-primary-contrast);
  --ion-color-homeLoginButton-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homeLoginButton-shade: var(--ion-color-primary-shade);
  --ion-color-homeLoginButton-tint: var(--ion-color-primary-tint);

  --ion-color-homePageMotoColor: #ffffff;
  --ion-color-homePageMotoColor-rgb: 255, 255, 255;
  --ion-color-homePageMotoColor-contrast: #000000;
  --ion-color-homePageMotoColor-contrast-rgb: 0, 0, 0;
  --ion-color-homePageMotoColor-shade: #e0e0e0;
  --ion-color-homePageMotoColor-tint: #ffffff;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-deliveryMethodAddressButtons: #f4f5f8;
  --ion-color-deliveryMethodAddressButtons-rgb: 244, 245, 248;
  --ion-color-deliveryMethodAddressButtons-contrast: #000000;
  --ion-color-deliveryMethodAddressButtons-contrast-rgb: 0, 0, 0;
  --ion-color-deliveryMethodAddressButtons-shade: #d7d8da;
  --ion-color-deliveryMethodAddressButtons-tint: #f5f6f9;

  --ion-color-changeDarkModeButton: #f4f5f8;
  --ion-color-changeDarkModeButton-rgb: 244, 245, 248;
  --ion-color-changeDarkModeButton-contrast: #000000;
  --ion-color-changeDarkModeButton-contrast-rgb: 0, 0, 0;
  --ion-color-changeDarkModeButton-shade: #d7d8da;
  --ion-color-changeDarkModeButton-tint: #f5f6f9;

  --ion-color-poweredByTextColor: #f4f5f8;
  --ion-color-poweredByTextColor-rgb: 244, 245, 248;
  --ion-color-poweredByTextColor-contrast: #000000;
  --ion-color-poweredByTextColor-contrast-rgb: 0, 0, 0;
  --ion-color-poweredByTextColor-shade: #d7d8da;
  --ion-color-poweredByTextColor-tint: #f5f6f9;

  --ion-color-ionMenuBadgesColor-shade: var(--ion-color-primary-shade);
  --ion-color-ionMenuBadgesColor-tint: var(--ion-color-primary-tint);

  --ion-color-ionMenuBadgesColor: var(--ion-color-primary);
  --ion-color-ionMenuBadgesColor-rgb: var(--ion-color-primary-rgb);
  --ion-color-ionMenuBadgesColor-contrast: var(--ion-color-primary-contrast);
  --ion-color-ionMenuBadgesColor-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );

  //MENU
  --menuBgColor: black;
  --menuTextColor: white;
  --menuMinWidthToShow: 1500;
  --ion-menu-selected-item-font-color: var(--ion-color-primary);

  //GENERAL
  --flattenedIconBackground: white;
  --headerLogoIconBackground: white;
  --generalHeaderColorBG: black;
  --generalHeaderColorText: orange;
  --generalBodyColorBG: black;
  --generalBodyColorText: white;
  --generalWarningColor: red;
  --buttonsBorderRadius: 0px;
  --pointsProgressBarColor: rgb(0, 0, 0);
  --pointsProgressBarColorLight: rgb(0, 0, 0);
  --pointsProgressBarColorLightSuccess: rgba(var(--ion-color-success-rgb), 0.4);
  --pointsProgressBarColorLightWarning: rgba(var(--ion-color-warning-rgb), 0.4);
  --pointsProgressBarColorLightBlack: rgba(var(--ion-color-black-rgb), 0.7);
  --sideMenuIconsColor: #616e7e;
  --ion-menu-selected-opacity: 0.14;

  // --split-pane-height: 100%;
  // --ion-modals-height: 100%;

  //CART
  --cartHeaderBg: green;
  --cartHeaderText: yellow;
  --cartHeaderPromoBtn: var(--cartHeaderText);
  --cartBodyBg: grey;
  --cartBodyText: black;

  --cartWarningLightColor: yellow;
  --cartWarningColor: red;
  --cartDeleteColor: red;

  --cartIonContentMargin: 100px;
  --cartHeaderMargin: 44px;
  --cartHeaderHeight: 101px;
  --cartScrollHeight: 900px;
  --cartCatalogScrollHeight: 900px;
  --cartGridMaxWidth: 1100px;
  --invoiceAddEditModalHeight: 500px;

  //Catalog / products
  --catalogHeaderBg: black; //TODO DELETE
  --catalogHeaderText: white; //TODO DELETE
  --catalogTitleBg: white;
  --catalogTitleText: black;
  --catalogBodyBg: black;
  --catalogBodyText: white;
  --catalogCreateOrderBtnBgColor: #ef473a;
  --catalogCreateOrderBtnTextColor: white;
  --catalogWarningColor: red;
  --catalogNewProductColor: yellow;
  --catalogLogoHeight: 60px;
  --catalogProductModalImageHeight: 250px;
  --catalogProductModalImageHeightSmallScreen1: 250px;
  --catalogProductModalImageHeightSmallScreen2: 250px;
  --catalogScrollHeight: 1000px;
  --catalogGridMaxWidth: 250px;
  --categoriesWarningColor: red;

  //PaymentModals
  --paymentModalHeight: 800px;
  --paymentModalWidth: 600px;

  //Product-deitails modal page

  //Store-Schedule Page
  --storeShedulePageMaxWidth: 700px;

  //PointsPage
  --pointsPageMaxWidth: 600px;

  //Ratings Page
  --ratingsPageMaxWidth: 500px;

  //InvoiceData Page
  --invoiceDataPageMaxWidth: 500px;

  //account-settings Page
  --acountSettingsPageMaxWidth: 500px;
  --ion-color-accountSettingsSaveBtn: #651515;
  --ion-color-accountSettingsSaveBtn-rgb: 101, 21, 21;
  --ion-color-accountSettingsSaveBtn-contrast: #ffffff;
  --ion-color-accountSettingsSaveBtn-contrast-rgb: 255, 255, 255;
  --ion-color-accountSettingsSaveBtn-shade: #591212;
  --ion-color-accountSettingsSaveBtn-tint: #742c2c;

  --ion-color-accountSettingsEditBtn: #7044ff;
  --ion-color-accountSettingsEditBtn-rgb: 112, 68, 255;
  --ion-color-accountSettingsEditBtn-contrast: #ffffff;
  --ion-color-accountSettingsEditBtn-contrast-rgb: 255, 255, 255;
  --ion-color-accountSettingsEditBtn-shade: #633ce0;
  --ion-color-accountSettingsEditBtn-tint: #7e57ff;
  //Payment-cards Page
  --paymentCardsPageMaxWidth: 600px;

  //Orders-page
  --ordersPageMaxWidth: 700px;
  /** canceled order **/
  --ion-color-canceled: #7044ff;
  --ion-color-canceled-rgb: 112, 68, 255;
  --ion-color-canceled-contrast: #ffffff;
  --ion-color-canceled-contrast-rgb: 255, 255, 255;
  --ion-color-canceled-shade: #633ce0;
  --ion-color-canceled-tint: #7e57ff;

  /** pending order **/
  --ion-color-pending: #eb445a;
  --ion-color-pending-rgb: 235, 68, 90;
  --ion-color-pending-contrast: #ffffff;
  --ion-color-pending-contrast-rgb: 255, 255, 255;
  --ion-color-pending-shade: #cf3c4f;
  --ion-color-pending-tint: #ed576b;

  /** pending payment **/
  --ion-color-pendingPayment: #f18137;
  --ion-color-pendingPayment-rgb: 241, 129, 55;
  --ion-color-pendingPayment-contrast: #000000;
  --ion-color-pendingPayment-contrast-rgb: 0, 0, 0;
  --ion-color-pendingPayment-shade: #d47230;
  --ion-color-pendingPayment-tint: #f28e4b;

  /** processing payment **/
  --ion-color-processingPayment: #d25d0f;
  --ion-color-processingPayment-rgb: 210, 93, 15;
  --ion-color-processingPayment-contrast: #ffffff;
  --ion-color-processingPayment-contrast-rgb: 255, 255, 255;
  --ion-color-processingPayment-shade: #b9520d;
  --ion-color-processingPayment-tint: #d76d27;

  /** completed **/
  --ion-color-complete: #0ab900;
  --ion-color-complete-rgb: 10, 185, 0;
  --ion-color-complete-contrast: #ffffff;
  --ion-color-complete-contrast-rgb: 255, 255, 255;
  --ion-color-complete-shade: #09a300;
  --ion-color-complete-tint: #23c01a;

  /** processing **/
  --ion-color-processing: #dde207;
  --ion-color-processing-rgb: 221, 226, 7;
  --ion-color-processing-contrast: #000000;
  --ion-color-processing-contrast-rgb: 0, 0, 0;
  --ion-color-processing-shade: #c2c706;
  --ion-color-processing-tint: #e0e520;

  //Discount-Coupons Page

  /** used coupon **/
  --ion-color-used-coupon: #7044ff;
  --ion-color-used-coupon-rgb: 112, 68, 255;
  --ion-color-used-coupon-contrast: #ffffff;
  --ion-color-used-coupon-contrast-rgb: 255, 255, 255;
  --ion-color-used-coupon-shade: #633ce0;
  --ion-color-used-coupon-tint: #7e57ff;

  /** expired coupon **/
  --ion-color-expired-coupon: #eb445a;
  --ion-color-expired-coupon-rgb: 235, 68, 90;
  --ion-color-expired-coupon-contrast: #ffffff;
  --ion-color-expired-coupon-contrast-rgb: 255, 255, 255;
  --ion-color-expired-coupon-shade: #cf3c4f;
  --ion-color-expired-coupon-tint: #ed576b;

  /** active coupon **/
  --ion-color-active-coupon: #0ab900;
  --ion-color-active-coupon-rgb: 10, 185, 0;
  --ion-color-active-coupon-contrast: #ffffff;
  --ion-color-active-coupon-contrast-rgb: 255, 255, 255;
  --ion-color-active-coupon-shade: #09a300;
  --ion-color-active-coupon-tint: #23c01a;

  //popups
  --magicLoginPopupHeight: 300px;
  --orderNowPopupHeight: 230px;
  --selectStorePopupHeight: 300px;
  --selectStoreInPaymentCardsPopupHeight: 330px;
  --selectStoreInPaymentCardsPopupHeight: 330px;
  --billingInfoHeight: 500px;
  //preloading
  --preloadingColorText: black;
  --preloadingColorBg: white;

  //validation product not valid
  --ion-color-notValid: #ffdfdf;
  --ion-color-notValid-rgb: 255, 209, 215;
  --ion-color-notValid-contrast: #000000;
  --ion-color-notValid-contrast-rgb: 0, 0, 0;
  --ion-color-notValid-shade: #e0b8bd;
  --ion-color-notValid-tint: #ffd6db;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  //--------------tags custom colors-----------------

  --ion-color-tagColorOne: #0054e9;
  --ion-color-tagColorOne-rgb: 0, 84, 233;
  --ion-color-tagColorOne-contrast: #ffffff;
  --ion-color-tagColorOne-contrast-rgb: 255, 255, 255;
  --ion-color-tagColorOne-shade: #004acd;
  --ion-color-tagColorOne-tint: #1a65eb;

  --ion-color-tagColorTwo: #eb445a;
  --ion-color-tagColorTwo-rgb: 235, 68, 90;
  --ion-color-tagColorTwo-contrast: #ffffff;
  --ion-color-tagColorTwo-contrast-rgb: 255, 255, 255;
  --ion-color-tagColorTwo-shade: #cf3c4f;
  --ion-color-tagColorTwo-tint: #ed576b;

  --ion-color-tagColorThree: #0ab900;
  --ion-color-tagColorThree-rgb: 10, 185, 0;
  --ion-color-tagColorThree-contrast: #ffffff;
  --ion-color-tagColorThree-contrast-rgb: 255, 255, 255;
  --ion-color-tagColorThree-shade: #09a300;
  --ion-color-tagColorThree-tint: #23c01a;

  --ion-color-tagColorFour: #d25d0f;
  --ion-color-tagColorFour-rgb: 210, 93, 15;
  --ion-color-tagColorFour-contrast: #ffffff;
  --ion-color-tagColorFour-contrast-rgb: 255, 255, 255;
  --ion-color-tagColorFour-shade: #b9520d;
  --ion-color-tagColorFour-tint: #d76d27;

  --ion-color-tagColorFour: #d25d0f;
  --ion-color-tagColorFour-rgb: 210, 93, 15;
  --ion-color-tagColorFour-contrast: #ffffff;
  --ion-color-tagColorFour-contrast-rgb: 255, 255, 255;
  --ion-color-tagColorFour-shade: #b9520d;
  --ion-color-tagColorFour-tint: #d76d27;

  --ion-color-tagColorFive: #ffc409;
  --ion-color-tagColorFive-rgb: 255, 196, 9;
  --ion-color-tagColorFive-contrast: #000000;
  --ion-color-tagColorFive-contrast-rgb: 0, 0, 0;
  --ion-color-tagColorFive-shade: #e0ac08;
  --ion-color-tagColorFive-tint: #ffca22;

  --ion-color-tagColorSix: #5f5f5f;
  --ion-color-tagColorSix-rgb: 95, 95, 95;
  --ion-color-tagColorSix-contrast: #ffffff;
  --ion-color-tagColorSix-contrast-rgb: 255, 255, 255;
  --ion-color-tagColorSix-shade: #545454;
  --ion-color-tagColorSix-tint: #6f6f6f;

  --ion-color-tagColorSeven: #7044ff;
  --ion-color-tagColorSeven-rgb: 112, 68, 255;
  --ion-color-tagColorSeven-contrast: #ffffff;
  --ion-color-tagColorSeven-contrast-rgb: 255, 255, 255;
  --ion-color-tagColorSeven-shade: #633ce0;
  --ion-color-tagColorSeven-tint: #7e57ff;

  --cartIconSrc: assets/flatIcons/trayDark.svg;
  --homepageBrightnessSlider: 40%;
  --beat-header-title-paddings: 0;

  --input-color: #000000;
  --input-border-focus: #fdf90b;

  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;
}

body.dark {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-notValid: #ffdfdf;
  --ion-color-notValid-rgb: 255, 209, 215;
  --ion-color-notValid-contrast: #000000;
  --ion-color-notValid-contrast-rgb: 0, 0, 0;
  --ion-color-notValid-shade: #e0b8bd;
  --ion-color-notValid-tint: #ffd6db;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-selectPaymentMethod: #fafafa;
  --ion-color-selectPaymentMethod-rgb: 250, 250, 250;
  --ion-color-selectPaymentMethod-contrast: #000000;
  --ion-color-selectPaymentMethod-contrast-rgb: 0, 0, 0;
  --ion-color-selectPaymentMethod-shade: #dcdcdc;
  --ion-color-selectPaymentMethod-tint: #fbfbfb;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-selected: #ffffff;
  --ion-color-selected-rgb: 255, 255, 255;
  --ion-color-selected-contrast: #000000;
  --ion-color-selected-contrast-rgb: 0, 0, 0;
  --ion-color-selected-shade: #e0e0e0;
  --ion-color-selected-tint: #ffffff;

  --ion-color-completeorder: #66c27a;
  --ion-color-completeorder-rgb: 102, 194, 122;
  --ion-color-completeorder-contrast: #000000;
  --ion-color-completeorder-contrast-rgb: 0, 0, 0;
  --ion-color-completeorder-shade: #5aab6b;
  --ion-color-completeorder-tint: #75c887;

  --ion-color-ordererror: #ec6565;
  --ion-color-ordererror-rgb: 236, 101, 101;
  --ion-color-ordererror-contrast: #000000;
  --ion-color-ordererror-contrast-rgb: 0, 0, 0;
  --ion-color-ordererror-shade: #d05959;
  --ion-color-ordererror-tint: #ee7474;

  --ion-color-deliveryMethodAddressButtons: #222428;
  --ion-color-deliveryMethodAddressButtons-rgb: 34, 36, 40;
  --ion-color-deliveryMethodAddressButtons-contrast: #ffffff;
  --ion-color-deliveryMethodAddressButtons-contrast-rgb: 255, 255, 255;
  --ion-color-deliveryMethodAddressButtons-shade: #1e2023;
  --ion-color-deliveryMethodAddressButtons-tint: #383a3e;

  --ion-color-verticalCategories: var(--ion-color-primary);
  --ion-color-verticalCategories-rgb: var(--ion-color-primary-rgb);
  --ion-color-verticalCategories-contrast: var(--ion-color-primary-contrast);
  --ion-color-verticalCategories-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-verticalCategories-shade: var(--ion-color-primary-shade);
  --ion-color-verticalCategories-tint: var(--ion-color-primary-tint);

  --ion-color-horizontalCategories: var(--ion-color-primary);
  --ion-color-horizontalCategories-rgb: var(--ion-color-primary-rgb);
  --ion-color-horizontalCategories-contrast: var(--ion-color-primary-contrast);
  --ion-color-horizontalCategories-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-horizontalCategories-shade: var(--ion-color-primary-shade);
  --ion-color-horizontalCategories-tint: var(--ion-color-primary-tint);

  --ion-color-homepageButtons: var(--ion-color-primary);
  --ion-color-homepageButtons-rgb: var(--ion-color-primary-rgb);
  --ion-color-homepageButtons-contrast: var(--ion-color-primary-contrast);
  --ion-color-homepageButtons-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homepageButtons-shade: var(--ion-color-primary-shade);
  --ion-color-homepageButtons-tint: var(--ion-color-primary-tint);

  --ion-color-homeBurgerButton: var(--ion-color-primary);
  --ion-color-homeBurgerButton-rgb: var(--ion-color-primary-rgb);
  --ion-color-homeBurgerButton-contrast: var(--ion-color-primary-contrast);
  --ion-color-homeBurgerButton-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homeBurgerButton-shade: var(--ion-color-primary-shade);
  --ion-color-homeBurgerButton-tint: var(--ion-color-primary-tint);

  --ion-color-homeLoginButton: var(--ion-color-primary);
  --ion-color-homeLoginButton-rgb: var(--ion-color-primary-rgb);
  --ion-color-homeLoginButton-contrast: var(--ion-color-primary-contrast);
  --ion-color-homeLoginButton-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );

  --ion-color-ionMenuBadgesColor: var(--ion-color-primary);
  --ion-color-ionMenuBadgesColor-rgb: var(--ion-color-primary-rgb);
  --ion-color-ionMenuBadgesColor-contrast: var(--ion-color-primary-contrast);
  --ion-color-ionMenuBadgesColor-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homeLoginButton-shade: var(--ion-color-primary-shade);
  --ion-color-homeLoginButton-tint: var(--ion-color-primary-tint);

  --cartIconSrc: assets/flatIcons/trayDark.svg;

  --ion-menu-selected-item-font-color: var(--ion-color-primary);

  --customSideMenuBackground: var(--ion-item-background) !important;

  --ion-color-changeDarkModeButton: var(--ion-color-primary);
  --ion-color-changeDarkModeButton-rgb: var(--ion-color-primary-rgb);
  --ion-color-changeDarkModeButton-contrast: var(--ion-color-primary-contrast);
  --ion-color-changeDarkModeButton-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-changeDarkModeButton-shade: var(--ion-color-primary-shade);
  --ion-color-changeDarkModeButton-tint: var(--ion-color-primary-tint);

  --ion-color-poweredByTextColor: var(--ion-color-dark);
  --ion-color-poweredByTextColor-rgb: var(--ion-color-dark-rgb);
  --ion-color-poweredByTextColor-contrast: var(--ion-color-dark-contrast);
  --ion-color-poweredByTextColor-contrast-rgb: var(
    --ion-color-dark-contrast-rgb
  );
  --ion-color-poweredByTextColor-shade: var(--ion-color-dark-shade);
  --ion-color-poweredByTextColor-tint: var(--ion-color-dark-tint);

  --ion-color-ionMenuSelectedColor: var(--ion-color-primary);
  --ion-color-ionMenuSelectedColor-shade: var(--ion-color-primary-shade);
  --ion-color-ionMenuSelectedColor-tint: var(--ion-color-primary-tint);
  --ion-color-ionMenuSelectedColor-rgb: var(--ion-color-primary-rgb);
  --ion-color-ionMenuSelectedColor-contrast: var(--ion-color-primary-contrast);
  --ion-color-ionMenuSelectedColor-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );

  --ion-color-customSideMenuItemBackground: var(--ion-item-background);
  --ion-color-customSideMenuItemBackground-contrast: var(
    --ion-item-background-rgb
  );

  ion-modal::part(content) {
    border: 1px solid #ffffff8f !important;
  }

  ion-modal.fullscreen::part(content) {
    border: none !important;
  }

  ion-alert {
    .alert-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }
}

body.battlenet-dark {
  --ion-color-primary: #ed1c24;
  --ion-color-primary-rgb: 237, 28, 36;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #d11920;
  --ion-color-primary-tint: #ef333a;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-notValid: #ffdfdf;
  --ion-color-notValid-rgb: 255, 209, 215;
  --ion-color-notValid-contrast: #000000;
  --ion-color-notValid-contrast-rgb: 0, 0, 0;
  --ion-color-notValid-shade: #e0b8bd;
  --ion-color-notValid-tint: #ffd6db;

  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

  --ion-color-selectPaymentMethod: #fafafa;
  --ion-color-selectPaymentMethod-rgb: 250, 250, 250;
  --ion-color-selectPaymentMethod-contrast: #000000;
  --ion-color-selectPaymentMethod-contrast-rgb: 0, 0, 0;
  --ion-color-selectPaymentMethod-shade: #dcdcdc;
  --ion-color-selectPaymentMethod-tint: #fbfbfb;

  --ion-color-black: #000000;
  --ion-color-black-rgb: 0, 0, 0;
  --ion-color-black-contrast: #ffffff;
  --ion-color-black-contrast-rgb: 255, 255, 255;
  --ion-color-black-shade: #000000;
  --ion-color-black-tint: #1a1a1a;

  --ion-color-selected: #ffffff;
  --ion-color-selected-rgb: 255, 255, 255;
  --ion-color-selected-contrast: #000000;
  --ion-color-selected-contrast-rgb: 0, 0, 0;
  --ion-color-selected-shade: #e0e0e0;
  --ion-color-selected-tint: #ffffff;

  --ion-color-completeorder: #66c27a;
  --ion-color-completeorder-rgb: 102, 194, 122;
  --ion-color-completeorder-contrast: #000000;
  --ion-color-completeorder-contrast-rgb: 0, 0, 0;
  --ion-color-completeorder-shade: #5aab6b;
  --ion-color-completeorder-tint: #75c887;

  --ion-color-ordererror: #ec6565;
  --ion-color-ordererror-rgb: 236, 101, 101;
  --ion-color-ordererror-contrast: #000000;
  --ion-color-ordererror-contrast-rgb: 0, 0, 0;
  --ion-color-ordererror-shade: #d05959;
  --ion-color-ordererror-tint: #ee7474;

  --ion-color-deliveryMethodAddressButtons: #222428;
  --ion-color-deliveryMethodAddressButtons-rgb: 34, 36, 40;
  --ion-color-deliveryMethodAddressButtons-contrast: #ffffff;
  --ion-color-deliveryMethodAddressButtons-contrast-rgb: 255, 255, 255;
  --ion-color-deliveryMethodAddressButtons-shade: #1e2023;
  --ion-color-deliveryMethodAddressButtons-tint: #383a3e;

  --ion-color-verticalCategories: var(--ion-color-primary);
  --ion-color-verticalCategories-rgb: var(--ion-color-primary-rgb);
  --ion-color-verticalCategories-contrast: var(--ion-color-primary-contrast);
  --ion-color-verticalCategories-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );

  --ion-color-homepageButtons: var(--ion-color-primary);
  --ion-color-homepageButtons-rgb: var(--ion-color-primary-rgb);
  --ion-color-homepageButtons-contrast: var(--ion-color-primary-contrast);
  --ion-color-homepageButtons-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homepageButtons-shade: var(--ion-color-primary-shade);
  --ion-color-homepageButtons-tint: var(--ion-color-primary-tint);

  --ion-color-homeBurgerButton: var(--ion-color-primary);
  --ion-color-homeBurgerButton-rgb: var(--ion-color-primary-rgb);
  --ion-color-homeBurgerButton-contrast: var(--ion-color-primary-contrast);
  --ion-color-homeBurgerButton-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homeBurgerButton-shade: var(--ion-color-primary-shade);
  --ion-color-homeBurgerButton-tint: var(--ion-color-primary-tint);

  --ion-color-homeLoginButton: var(--ion-color-primary);
  --ion-color-homeLoginButton-rgb: var(--ion-color-primary-rgb);
  --ion-color-homeLoginButton-contrast: var(--ion-color-primary-contrast);
  --ion-color-homeLoginButton-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-homeLoginButton-shade: var(--ion-color-primary-shade);
  --ion-color-homeLoginButton-tint: var(--ion-color-primary-tint);

  --ion-menu-selected-background: #000000;

  --ion-menu-selected-item-font-color: var(--ion-color-primary);

  --customSideMenuBackground: var(--ion-item-background) !important;

  --customSideMenuBackground: var(--ion-item-background) !important;

  --ion-color-changeDarkModeButton: var(--ion-color-primary);
  --ion-color-changeDarkModeButton-rgb: var(--ion-color-primary-rgb);
  --ion-color-changeDarkModeButton-contrast: var(--ion-color-primary-contrast);
  --ion-color-changeDarkModeButton-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );
  --ion-color-changeDarkModeButton-shade: var(--ion-color-primary-shade);
  --ion-color-changeDarkModeButton-tint: var(--ion-color-primary-tint);

  --ion-color-poweredByTextColor: var(--ion-color-dark);
  --ion-color-poweredByTextColor-rgb: var(--ion-color-dark-rgb);
  --ion-color-poweredByTextColor-contrast: var(--ion-color-dark-contrast);
  --ion-color-poweredByTextColor-contrast-rgb: var(
    --ion-color-dark-contrast-rgb
  );
  --ion-color-poweredByTextColor-shade: var(--ion-color-dark-shade);
  --ion-color-poweredByTextColor-tint: var(--ion-color-dark-tint);

  --ion-color-ionMenuSelectedColor: var(--ion-color-primary);
  --ion-color-ionMenuSelectedColor-shade: var(--ion-color-primary-shade);
  --ion-color-ionMenuSelectedColor-tint: var(--ion-color-primary-tint);
  --ion-color-ionMenuSelectedColor-rgb: var(--ion-color-primary-rgb);
  --ion-color-ionMenuSelectedColor-contrast: var(--ion-color-primary-contrast);
  --ion-color-ionMenuSelectedColor-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );

  --ion-color-ionMenuBadgesColor: var(--ion-color-primary);
  --ion-color-ionMenuBadgesColor-rgb: var(--ion-color-primary-rgb);
  --ion-color-ionMenuBadgesColor-contrast: var(--ion-color-primary-contrast);
  --ion-color-ionMenuBadgesColor-contrast-rgb: var(
    --ion-color-primary-contrast-rgb
  );

  --ion-color-customSideMenuItemBackground: var(--ion-item-background);
  --ion-color-customSideMenuItemBackground-contrast: var(
    --ion-item-background-rgb
  );

  --cartIconSrc: assets/flatIcons/trayDark.svg;

  ion-modal {
    .modal-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }

  ion-alert {
    .alert-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }
}

/*
   * iOS Dark Theme
   * -------------------------------------------
   */

.ios body.dark {
  --cartIconSrc: assets/flatIcons/trayDark.svg;
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #000000;

  --ion-card-background: #29292b;
  ion-modal {
    .modal-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }
  ion-alert {
    .alert-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }
}

.ios body.battlenet-dark {
  --cartIconSrc: assets/flatIcons/trayDark.svg;
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #000000;

  --ion-card-background: #29292b;
  ion-modal {
    .modal-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }
  ion-alert {
    .alert-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }
}
/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body.battlenet-dark {
  --cartIconSrc: assets/flatIcons/trayDark.svg;
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #29292b;

  ion-modal {
    &::part(content) {
      border: 1px solid #ffffff8f !important;
    }
  }
  ion-alert {
    .alert-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }
}

.md body.dark {
  --cartIconSrc: assets/flatIcons/trayDark.svg;
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;

  --ion-card-background: #29292b;

  ion-modal {
    &::part(content) {
      border: 1px solid #ffffff8f !important;
    }
  }
  ion-alert {
    .alert-wrapper {
      border: 1px solid #ffffff8f !important;
    }
  }
}
